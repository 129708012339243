import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../environment/environment'
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PostPropertyService {

    selectedProperty: any = null;

    constructor(private http: HttpClient) { }

    createPost(req: any): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            localStorage.getItem('loginToken')
        );
        return this.http.post(`${environment.apiUrl}/property/v1`, req, {
            headers: header
        })
    }

    updateBasicDetails(req: any, id): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            localStorage.getItem('loginToken')
        );
        return this.http.post(`${environment.apiUrl}/property/v1/${id}`, req, {
            headers: header
        })
    }

    getPropDetail(id): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            localStorage.getItem('loginToken')
        );
        return this.http.get(`${environment.apiUrl}/property/v1/details/${id}`, {
            headers: header
        })
    }

    listPost(): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            localStorage.getItem('loginToken')
        );
        return this.http.get(`${environment.apiUrl}/property/v1/`, {
            headers: header
        })
    }
}
