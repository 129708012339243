import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ReitComponent } from './reit/reit.component';
import { AuthGuard } from './service/auth.guard';
import { NoAuthGuard } from './service/no-auth.guard';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'reit', component: ReitComponent },
  {
    path: 'post-your-property',
    loadChildren: './post-your-property/post-your-property.module#PostYourPropertyModule',
    canActivate: [AuthGuard],
    data: {
      title: 'Post Your Property'
    },
  },
  {
    path: 'user-dashboard',
    loadChildren: './user-dashboard/user-dashboard.module#UserDashboardModule',
    canActivate: [AuthGuard],
    data: {
      title: 'User Dashboard'
    },
  },
  {
    path: 'search-results',
    loadChildren: './search-results/search-results.module#SearchResultsModule',
    data: {
      title: 'Search results'
    },
  },
  {
    path: 'login',
    loadChildren: './login/login.module#LoginModule',
    canActivate: [NoAuthGuard],
    data: {
      title: 'Login'
    },
  },
  {
    path: 'register',
    loadChildren: './register/register.module#RegisterModule',
    canActivate: [NoAuthGuard],
    data: {
      title: 'Register'
    },
  },
  {
    path: 'wishlist',
    loadChildren: './wishlist/wishlist.module#WishlistModule',
    canActivate: [AuthGuard],
    data: {
      title: 'Wishlist'
    },
  },
  {
    path: 'set-alerts',
    loadChildren: './alerts/alerts.module#AlertsModule',
    canActivate: [AuthGuard],
    data: {
      title: 'Set Alerts'
    },
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
