import { Component, OnInit, AfterViewInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit() {
   
  }
  ngAfterViewInit(){
    $(document).ready(function () {
      
      $(".price-range").ionRangeSlider({
          skin: "big",
          type: "double",
          grid: !0,
          min: 0,
          max: 1e4,
          from: 1300,
          to: 7600,
          prefix: ""
          });
  });
  }

}
