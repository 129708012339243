import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PropertyRowListComponent } from './property-row-list/property-row-list.component';
import { PropertySingleGridComponent } from './property-single-grid/property-single-grid.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [PropertyRowListComponent, PropertySingleGridComponent],
  imports: [
    CommonModule,
    RouterModule,
  ],
  exports: [
    PropertyRowListComponent,
    PropertySingleGridComponent
  ]
})
export class SharedModule { }
