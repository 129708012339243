import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-property-single-grid',
  templateUrl: './property-single-grid.component.html',
  styleUrls: ['./property-single-grid.component.css']
})
export class PropertySingleGridComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
