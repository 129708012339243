import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../environment/environment'

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  profile(){
    let header = new HttpHeaders().set(
      "Authorization",
      localStorage.getItem('loginToken')
    );

    return this.http.get(`${environment.apiUrl}/user/v1/profile`,{
      headers: header
    })
  }

  changePassword(body){
    let header = new HttpHeaders().set(
      "Authorization",
      localStorage.getItem('loginToken')
    );

    return this.http.post(`${environment.apiUrl}/auth/v1/change-password`,body,{
      headers: header
    })
  }

  updateProfile(body){
    let header = new HttpHeaders().set(
      "Authorization",
      localStorage.getItem('loginToken')
    );

    return this.http.put(`${environment.apiUrl}/user/v1/profile`,body,{
      headers: header
    })
  }
}
