import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import * as $ from 'jquery';
import { ApiService } from '../../service/api.service';
import { PostPropertyService } from 'src/app/service/post-property.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @Input()
  noBackground = true;

  isUserSignedIn = false;

  isOpen = false;
  profileData = {};
  constructor(private apiService: ApiService,
              private authService: AuthService,
              private postPropService: PostPropertyService) { }

  ngOnInit() {
    this.apiService.profile().subscribe( (response) => {
      this.profileData = response['data'];
    });
    this.isUserSignedIn = this.authService.isUserSignedIn();
  }
  showSideNav() {
    this.isOpen = !this.isOpen;
  }

  logout(){
    localStorage.removeItem("loginToken");
    window.location.reload();
  }

  removeSelectedData() {
    this.postPropService.selectedProperty = null;
  }

}
