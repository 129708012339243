import { Component, OnInit, Input } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-property-row-list',
  templateUrl: './property-row-list.component.html',
  styleUrls: ['./property-row-list.component.css']
})
export class PropertyRowListComponent implements OnInit {

  @Input()
  darkMode = false;

  @Input()
  heading = '';
  constructor() { }

  ngOnInit() {
    $(document).ready(function() {
      $(".featured__property-carousel").length && $(".featured__property-carousel").owlCarousel({
        loop: !0,
        margin: 30,
        dots: !0,
        nav: !1,
        rtl: !1,
        autoplayHoverPause: !1,
        autoplay: !0,
        singleItem: !0,
        smartSpeed: 1200,
        navText: ['<i class="fa fa-arrow-left"></i>', '<i class="fa fa-arrow-right"></i>'],
        responsive: {
          0: {
            items: 1,
            center: !1
          },
          480: {
            items: 1,
            center: !1
          },
          600: {
            items: 1,
            center: !1
          },
          768: {
            items: 3
          },
          992: {
            items: 4
          },
          1200: {
            items: 4
          },
          1366: {
            items: 3
          },
          1400: {
            items: 4
          }
        }
      })
    });
  }

}
