import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  public showTransparentHeader = false;
  constructor( private route: ActivatedRoute, private router: Router, private url: LocationStrategy ) {}

  ngOnInit() {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
          const currentUrl = (event as NavigationStart).url;
          if (currentUrl === '/' || currentUrl === '/home' || currentUrl === '/reit') {
            this.showTransparentHeader = true;
          } else {
            this.showTransparentHeader = false;
          }
      }
  });
  }
}
