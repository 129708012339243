import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../environment/environment'

@Injectable({
  providedIn: 'root'
})
export class AuthService {
header = new HttpHeaders()
  constructor( private http: HttpClient,  ) { }



  registerUser(body){
    return this.http.post(`${environment.apiUrl}/auth/v1/signup`, body)
  }


  verifyOtp(body){
    let header = new HttpHeaders().set(
      "Authorization",
      localStorage.getItem('signuptoken')
    );

    return this.http.post(`${environment.apiUrl}/auth/v1/signup`, body,{
       headers: header
    })

  }
  verifyPasword(body){
    let header = new HttpHeaders().set(
      "Authorization",
      localStorage.getItem('otpToken')
    );

    return this.http.post(`${environment.apiUrl}/auth/v1/signup-update-password`, body,{
      headers: header

    })
  }

  isUserSignedIn() {
    return ( localStorage.getItem('loginToken')) ? true : false;
  }

  login(body){
    return this.http.post(`${environment.apiUrl}/auth/v1/signin`, body)
  }


}
